import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import HowToAvailView from './how-to-avail-view'

const HowToAvailController = () => {
  const [loading, setLoading] = useState(true)
  const [insurance, setInsurance] = useState([])
  const { i18n } = useTranslation()
  const getHowToAvail = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'page',
      params: {
        slug: 'how-to-avail-of-benefits',
        lang: i18n.language,
      },
    })
      .then(({ data }) => {
        setInsurance(data.content)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getHowToAvail()
  }, [i18n.language])

  const viewProps = {
    loading,
    data: insurance,
  }
  return <HowToAvailView {...viewProps} />
}

export default HowToAvailController
